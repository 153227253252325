import React from 'react';
import {graphql, Link} from 'gatsby';
//import PubSub from 'pubsub-js';
//import cookie from 'react-cookies'
import SEO from '../components/SEO'

const theMiddleOfTheWorld = require("../images/the-middle-of-the-world.png")
const theWorkshopOfMattMontini = require("../images/the-workshop-of-matt-montini.png")
const theCosmosYonatanVinitsky = require("../images/the-cosmos-yonatan-vinitsky.png")

class PageHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // console.log(this.props)
    const {
      options,
      page
    } = this.props.data
    return (
      <div className="home">
        <SEO
          pageTitle={page.data.title.text}
          pageDescription={page.data.description.text}
          template="template-home"
          // pathname={location.pathname} 
          /> 

        <header className="x header">
          <h1>Yonatan Vinitsky</h1>
          <nav>
            <ul>
              {/* <li>
              <Link to="/thecosmos">The Cosmos</Link>
              </li> */}
              <li>
                <a href="https://www.e-m-p-i-r-e.eu/yonatan/" target="_blank">
                  <img src={theMiddleOfTheWorld} alt="the middle of the world" width="177"/>
                </a>
              </li>
              <li>
                <Link to="/thecosmos">
                  <img src={theCosmosYonatanVinitsky} alt="the cosmos yonatan vinitsky" width="106"/>
                </Link>
              </li>
              <li>
                <a href="https://the-workshop-of-matt-montini.myshopify.com/" target="_blank">
                  <img src={theWorkshopOfMattMontini} alt="the workshop of matt montini" width="149"/>
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <footer>The Estate of  Matt Montini</footer>
      </div>
      
    )
  }
}

export default PageHome;

export const pageQuery = graphql `
  query homePage($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      data{
        title{text}
        description{text}
      }
    }
    options: prismicOptions {
      ..._prismicOptions
    }
  }
  
`   